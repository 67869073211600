import request from "@/utils/request";
import {
  UPLOAD_BULK_SALE_SERVICE,
  GET_BULK_SALES_SERVICE,
  GET_UNKNOWN_SERIAL_LIST_SERVICE,
  GET_SALES_DATA_SERVICE,
  DOWNLOAD_BULK_SALES_SERVICE,
  UPDATE_SALES_DATA_SERVICE,
  DOWNLOAD_SALES_DATA_SERVICE,
  CHECK_SERIAL_NUMBER_SERVICE,
  API_SEND_EMAIL_REQUEST,
  API_EXPORT_SALES_DATA,
  API_VALIDATE_UNKNOWN_SERIAL_NUMBER,
  API_EXPORT_BMS
} from "../constants/services";

import { DEFAULT_TIMEOUT, EXTENDED_TIMEOUT } from "../constants/index";

export async function getBulkSaleList(postData) {
  return await request({
    url: GET_BULK_SALES_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function uploadBulkSale(postData) {
  let url = UPLOAD_BULK_SALE_SERVICE;
  return await request({
    url: url,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getUnknownSerialList(postData) {
  return await request({
    url: GET_UNKNOWN_SERIAL_LIST_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getSalesData(postData) {
  return await request({
    url: GET_SALES_DATA_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateSalesData(postData) {
  return await request({
    url: UPDATE_SALES_DATA_SERVICE,
    method: "put",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadBulkSaleById(postData) {
  return await request({
    url: DOWNLOAD_BULK_SALES_SERVICE + postData.id,
    method: "get",
    params: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function downloadSalesData(postData) {
  return await request({
    url: DOWNLOAD_SALES_DATA_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function sendEmail(postData) {
  return await request({
    url: `${API_SEND_EMAIL_REQUEST}${postData.email ? `?email=${postData.email}` : ``}${
      postData.ccf ? `&ccf=${postData.ccf}` : ``}${
      postData.sdf ? `&sdf=${postData.sdf}` : ``}${
      postData.edf ? `&edf=${postData.edf}` : ``}${
      postData.fyf ? `&fyf=${postData.fyf}` : ``}${
      postData.qf ? `&qf=${postData.qf}` : ``}`,
    method: "get",
    timeout: EXTENDED_TIMEOUT
  });
}

export async function checkSerialNumber(postData) {
  let serial = null;

  if (postData.serial) {
    serial = postData.serial;
  }

  return await request({
    url: CHECK_SERIAL_NUMBER_SERVICE + serial,
    method: "get",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function exportSalesDataRequest(postData) {
  return await request({
    url: `${API_EXPORT_SALES_DATA}${postData.ccf ? `?ccf=${postData.ccf}` : ``}${
      postData.sdf ? `&sdf=${postData.sdf}` : ``}${
      postData.edf ? `&edf=${postData.edf}` : ``}${
      postData.fyf ? `&fyf=${postData.fyf}` : ``}${
      postData.qf ? `&qf=${postData.qf}` : ``}`,
    method: "get",
    timeout: EXTENDED_TIMEOUT
  });
}

export async function exportRetailBmsRequest(postData) {
  return await request({
    url: API_EXPORT_BMS,
    method: "get",
    params: postData,
    timeout: EXTENDED_TIMEOUT
  });
}

export async function validateUnknownSerialNumberRequest(postData) {
  return await request({
    url: API_VALIDATE_UNKNOWN_SERIAL_NUMBER,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}
